import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Logo = () => (
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageLogo = data.imageLogo.childImageSharp.fluid

        return (
          <Img fluid={imageLogo} alt='South Bay Fire Inc. logo' />
        )
      }
    }
  />
)

export default Logo

const query = graphql`
  query LogoQuery {
    imageLogo: file(relativePath: { eq: "logo/south-bay-fire-inc-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 240) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`