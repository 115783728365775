import React from "react"
import { Link } from 'gatsby'

const menuItem = 'text-white text-3xl mb-4'

const MobileNavList = ({ close }) => (
  <div className="w-full flex flex-col p-0">
    <Link to="/about" onClick={close} key="1" className={menuItem}>
      About
    </Link>

    <Link to="/services" onClick={close} key="2" className={menuItem}>
      Services
    </Link>

    <Link to="/products" onClick={close} key="2" className={menuItem}>
      Products
    </Link>

    <Link to="/contact" onClick={close} key="2" className={menuItem}>
      Contact
    </Link>

    <a href='tel:310-376-6901'>
      <button className="w-full bg-orange-800 hover:bg-orange-700 text-white text-2xl font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8">
        <svg class="fill-current text-white inline-block h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"/>
        </svg>
        (310) 376-6901
      </button>
    </a>
  </div>
)

export default MobileNavList