import React from 'react'
import { Link } from 'gatsby'
import Logo from '../common/Logo'

const menuLink = 'text-white uppercase text-sm font-bold ml-6 mr-3'

const HeaderNav = () => (
  <nav className="absolute top-0 left-auto right-auto sm:w-full md:w-full lg:w-11/12 xl:w-8/12 flex items-center justify-between flex-wrap bg-transparent p-4">
    <div className="w-48 mr-6">
      <Link to="/">
        <Logo whiteLogo />
      </Link>
    </div>

    <div className="sm:hidden md:hidden flex items-center w-auto">
      <div>
        <Link to="/" className={menuLink}>Home</Link>
        <Link to="/about" className={menuLink}>About</Link>
        <Link to="/services" className={menuLink}>Services</Link>
        <Link to="/products" className={menuLink}>Products</Link>
        <Link to="/contact" className={menuLink}>Contact</Link>

        <a href='tel:310-376-6901'>
          <button className="bg-orange-800 hover:bg-orange-700 text-white font-bold py-3 px-6 ml-6 rounded-full border-b-4 border-orange-900">
            <svg class="fill-current text-white inline-block h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"/>
            </svg>
            (310) 376-6901
          </button>
        </a>
      </div>
    </div>
  </nav>
)

export default HeaderNav