import React from 'react'
import { graphql, StaticQuery, Link } from "gatsby"
import Img from 'gatsby-image'

import Logo from '../common/Logo'

const Container = 'flex flex-col sm:items-center pl-12 sm:p-0 sm:mb-6'
const Title = 'text-lg text-blue-200 font-bold mb-4'
const MenuLink = 'text-white mb-2 underline'

const FooterNav = () => (
  <StaticQuery
    query={query}
    render={
      data => {
        const imageCS = data.imageCS.childImageSharp.fluid
        const imageNFPA = data.imageNFPA.childImageSharp.fluid

        return (
          <footer className='bg-gray-900 px-4 py-24 sm:py-12'>
            <div className='grid grid-cols-3 sm:grid-cols-1 gap-4 sm:w-full md:w-11/12 w-8/12 xl:w-6/12 mx-auto mb-16'>
              <div className="w-48 sm:mb-12 sm:mx-auto">
                <Link to="/">
                  <Logo />
                </Link>
              </div>

              <div className={Container}>
                <h2 className={Title}>Company</h2>

                <p className={MenuLink}><Link to='/about'>About</Link></p>
                <p className={MenuLink}><Link to='/services'>Services</Link></p>
                <p className={MenuLink}><Link to='/products'>Products</Link></p>
                <p className={MenuLink}><Link to='/contact'>Contact</Link></p>
              </div>

              <div className={Container}>
                <h2 className={Title}>Legal</h2>

                <p className={MenuLink}><Link to='/terms'>Terms</Link></p>
                <p className={MenuLink}><Link to='/privacy'>Privacy</Link></p>
              </div>
            </div>

            <div className='flex items-center justify-center sm:w-full w-8/12 xl:w-6/12 mx-auto mb-4'>
              <Img fluid={imageCS} alt='CalSafe logo' className='w-32 mx-4 rounded-md' />
              <Img fluid={imageNFPA} alt='NFPA logo' className='w-16 mx-4' />
            </div>

            <p className='text-base text-gray-400 text-center mb-2'>Goldman Sachs 10,000 Small Business Accredited</p>

            <p className='text-sm text-gray-500 text-center mb-4'>©{new Date().getFullYear()}. South Bay Fire, Inc.</p>

            <p className='text-sm text-gray-700 text-center'>Site by <a href='https://www.fastweb.dev' target='_blank' rel='noopener noreferrer' className='underline hover:text-white'>fastweb.dev</a></p>
          </footer>
        )
      }
    }
  />
)

export default FooterNav

const query = graphql`
  query FooterNavQuery {
    imageCS: file(relativePath: { eq: "logo/calsafe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageNFPA: file(relativePath: { eq: "logo/nfpa.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`